<div class="modal-header">
  <h4>{{title}}</h4>
  <button type="button" class="close" aria-label="Close">
    <span aria-hidden="true">&times;</span>
  </button>
</div>
<div class="modal-body">
  <form *ngIf="option==1" class="card m-0" [formGroup]="editHeadquater">
        <div class="row">
          <div class="col-md-12">
            <div class="form-group">
              <input class="form-control" required type="text" placeholder="Company Name" formControlName="name" [ngClass]="{ 'is-invalid': submittedHeadquater && fh.name.errors }">
              <div *ngIf="submittedHeadquater && fh.name.errors" class="text text-danger mt-1">This Field is required</div>
            </div>
          </div>
        </div>
    </form>

    <form *ngIf="option==2" class="card m-0" [formGroup]="editBranch">
      <div class="row">
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label">Name</label>
            <input class="form-control" required type="text" placeholder="Company Name" formControlName="name" [ngClass]="{ 'is-invalid': submittedBranch && fbr.name.errors }">
            <div *ngIf="submittedBranch && fbr.name.errors" class="text text-danger mt-1">This Field is required</div>
          </div>
        </div>
        <div class="col-md-12">
          <div class="form-group">
            <label class="form-label">Company HeadQuater</label>
            <select class="form-control btn-square" formControlName="headquater" [ngClass]="{ 'is-invalid': submittedBranch && fbr.headquater.errors }">
              <option value="" selected>Select</option>
              <ng-container *ngFor='let data of listHeadQuaters' ><option [value]="data.id" *ngIf="data.active=='1'">{{data.name}}</option>
              </ng-container>    
            </select>
            <div *ngIf="submittedBranch && fbr.headquater.errors" class="text text-danger mt-1">This Field is required</div>
          </div>
        </div>                
      </div>
    </form>

    <form *ngIf="option==3" class="card" [formGroup]="editConsultant">
          <div class="row">
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">First Name</label>
                <input class="form-control" required type="text" placeholder="First Name" formControlName="firstName" [ngClass]="{ 'is-invalid': submittedConsultant && fc.firstName.errors }">
                <div *ngIf="submittedConsultant && fc.firstName.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Last Name</label>
                <input class="form-control" required type="text" placeholder="Last Name" formControlName="lastName" [ngClass]="{ 'is-invalid': submittedConsultant && fc.lastName.errors }">
                <div *ngIf="submittedConsultant && fc.lastName.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Agency Staff Title</label>
                <select class="form-control btn-square" formControlName="title">
                  <option value="" selected>Select</option>
                  <option *ngFor="let data of titles; let i = index" value="{{data.id}}">{{data.name}}</option>       
                </select>
                <div *ngIf="submittedConsultant && fc.title.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Phone</label>
                <input class="form-control" required type="text" placeholder="Phone" formControlName="phone" [ngClass]="{ 'is-invalid': submittedConsultant && fc.phone.errors }">
                <div *ngIf="submittedConsultant && fc.phone.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Email</label>
                <input class="form-control" required type="text" placeholder="Email" formControlName="email" [ngClass]="{ 'is-invalid': submittedConsultant && fc.email.errors }">
                <div *ngIf="submittedConsultant && fc.email.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
            <div class="col-md-12">
              <div class="form-group">
                <label class="form-label">Company Branch</label>
                <select class="form-control btn-square" formControlName="branch" [ngClass]="{ 'is-invalid': submittedConsultant && fc.branch.errors }">
                  <option value="" selected>Select</option>
                  <ng-container *ngFor='let data of listBranches' ><option [value]="data.id" *ngIf="data.active=='1'">{{data.name}}</option>
                  </ng-container>    
                </select>
                <div *ngIf="submittedConsultant && fc.branch.errors" class="text text-danger mt-1">This Field is required</div>
              </div>
            </div>
          </div>
      </form>
</div>
<div class="modal-footer" style="text-align: center">
  <button class="btn btn-danger" type="submit"   (click)="close();">Cancel</button>
  <button class="btn btn-primary" type="submit" (click)="updateInfo()">Update </button>
</div>
